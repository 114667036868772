$(function () {


	// lazy load bgs
	$('.slide').each(function () {
		$(this).css('background-image', "url('" + $(this).data('background') + "')");
	});

	// slider
	$('.arrow').click(function () {
		var $this = $(this);
		var next;
		var element = $('.slider_ctrls');
		var slide = parseInt(element.data('position'));
		var sliderwrp = $('.slider_wrp');
		if ($(this).hasClass('left')) {
			next = slide - 1;
			if(next == 0){next = 6;}
		} else {
			next = slide + 1;
			if(next == 7){next = 1;}
		}
		$('.slider_sections ul li').removeClass('active');
		if(next != 1 ){$('.slider_sections ul li:eq('+ (next - 2 )+')').addClass('active'); }
		sliderwrp.removeClass('pos' + slide);
		sliderwrp.addClass('pos' + next);
		element.data('position', next);
	});
	$('.slider_sections ul li').click(function(){
		var index = $(this).index('.slider_sections ul li') +2;
		var sliderwrp = $('.slider_wrp');
		var element = $('.slider_ctrls');
		$('.slider_sections ul li').removeClass('active');
		$(this).addClass('active');
		sliderwrp.removeClass('pos1 pos2 pos3 pos4 pos5 pos6');
		sliderwrp.addClass('pos' + index);
		element.data('position', index);
	});


	$.stellar();


	// contact form
	$('.contactinteraction input').keyup(function (e) {
		var $this = $(this);
		var key = e.which;
		e.preventDefault();
		if(key == 13)  // the enter key code
		{
			nextsection($this);
		}
		if($this.hasClass('namecomplete')) {
			var name = $(this).val();
			$('#fname').html(name);
		}
	});
	$('.subjectoption').click(function(e){
		e.preventDefault();
		if($(this).closest('.contactinteraction').hasClass('active')){
			var $this =  $(this);
			var subject = $this.val();
			$('.subjectoption').removeClass('active');
			$this.addClass('active');
			subject = $this.html();
			nextsection($this);
			$('[name="requestsubject"]').val(subject);

		}
	});
	$('.subjectoption_other').keyup(function(e){
		e.preventDefault();
			var $this =  $(this);
			var subject = $this.val();
			$('.subjectoption').removeClass('active');
			$('[name="subject"]').val(subject);

	});
	$('#confirm_msg').click(function(e){
		e.preventDefault();
		nextsection($(this));
	});
	$('.formbuttons button').click(function(){
		var target = $(this).data('target');
		$('.contactinteraction').removeClass('active');
		$('#'+target).addClass('active');
		$('.formbuttons button').removeClass('active');
		$(this).addClass('active');
	});
	var nextsection = function(element){
		var $this =  element;
		var closest = $this.closest('.contactinteraction');
		var next = closest.next('.contactinteraction');
		var currentid = closest.attr('id');
		var sectionid = next.attr('id');
		if(currentid !== 'email'){
			$('button[data-target]').removeClass('active');
			$('button[data-target="'+sectionid+'"]').addClass('active');
			closest.removeClass('active');
			closest.next('.contactinteraction').addClass('active');
		}
	};

	// Product Filter
	$('.product_filter').change(function(){
		var $this = $(this);
		var filter = $this.val();
		if(filter != 'all' ){

			var position = parseInt(filter.replace('category','')) + 1;
			position = 'pos' + position;
			$('.slider_wrp').removeClass('pos6 pos1 pos2 pos3 pos4 pos5').addClass(position);

			$('.product').each(function(){
				var $this = $(this);
				if($this.hasClass(filter)){
					$this.removeClass('hide');
					setTimeout(function(){$this.removeClass('hidden')},50);
				}else{
					$this.addClass('hidden');
					setTimeout(function(){$this.addClass('hide')},400);
				}
			});
		}else{
			$('.slider_wrp').removeClass('pos6 pos1 pos2 pos3 pos4 pos5');
			$('.product').removeClass('hide');
			setTimeout(function(){$('.product').removeClass('hidden');},50);
		}
	});

	// push footer
	var wh = $(window).height();
	var mh = $('main').outerHeight();
	var hh = $('header').outerHeight();
	var fh = $('footer').outerHeight();
	var safe = wh - hh - fh;
	$('main').css('min-height', safe+'px');


	// GOOGLE MAP - Contact
		var map;
		var montreal = new google.maps.LatLng(45.460955, -73.8274627);
		var infowindow = new google.maps.InfoWindow();

		// Data for the markers consisting of a name, a LatLng and a zIndex for the
		// order in which these markers should display on top of each other.
		var halls = [
			['Nviron BioSolutions Inc', 45.460955, -73.8274627, 4, '1 Holiday Av. East Tower, Suite 501, Pointe-Claire, Quebec, H9R 5N3, Canada', '+1.450.621.5848', '+1.450.621.8767', 'info@nvironbiosolutions.com'],
		];
		var image = {url: '../images/mapicon.png'};

		function initialize() {

			var myLatLng = {lat: 45.460955, lng: -73.8274627};

			// white
			var roadAtlasStyles = [
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
				}, {
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
				}, {
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [{"color": "#ffffff"}, {"lightness": 17}]
				}, {
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
				}, {
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [{"color": "#ffffff"}, {"lightness": 18}]
				}, {
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [{"color": "#ffffff"}, {"lightness": 16}]
				}, {
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
				}, {
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [{"color": "#dedede"}, {"lightness": 21}]
				}, {
					"elementType": "labels.text.stroke",
					"stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
				}, {
					"elementType": "labels.text.fill",
					"stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
				}, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
					"featureType": "transit",
					"elementType": "geometry",
					"stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
				}, {
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [{"color": "#fefefe"}, {"lightness": 20}]
				}, {
					"featureType": "administrative",
					"elementType": "geometry.stroke",
					"stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
				}
			];
			// green
			var roadAtlasStyles = [{"featureType":"landscape","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":129.33333333333334},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#B6FF00"},{"saturation":63.79310344827584},{"lightness":-47.61960784313726},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#00B7FF"},{"saturation":-31.19999999999996},{"lightness":2.1803921568627374},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#00B5FF"},{"saturation":-33.33333333333343},{"lightness":27.294117647058826},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#00B7FF"},{"saturation":8.400000000000006},{"lightness":36.400000000000006},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#ABFF00"},{"saturation":61.80000000000001},{"lightness":13.800000000000011},{"gamma":1}]}];


			var mapOptions = {
				zoom: 8,
				center: myLatLng,
				scrollwheel: false,
				disableDoubleClickZoom: true,
				mapTypeControlOptions: {
					mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'usroadatlas']
				}
			};

			map = new google.maps.Map(document.getElementById('map-canvas'),
				mapOptions);

			var styledMapOptions = {};

			var pmgmap = new google.maps.StyledMapType(
				roadAtlasStyles, styledMapOptions);

			map.mapTypes.set('usroadatlas', pmgmap);
			map.setMapTypeId('usroadatlas');

			var bounds = new google.maps.LatLngBounds();

			setMarkers(map, halls, bounds);

			map.fitBounds(bounds);

		}

		function setMarkers(map, halls, bounds) {
			for (var i = 0; i < halls.length; i++) {
				var hall = halls[i];
				var marker = new google.maps.Marker({
					position: {lat: hall[1], lng: hall[2]},
					map: map,
					icon: image,
					title: hall[0],
					zIndex: hall[3]
				});
				bounds.extend(marker.position);


				var content = '<div class="infowindow"><h3>' + hall[0] + '</h3>' +
					'<p>' + hall[4] + '</p>' +
					'<p><b>Tel : </b>' + hall[5] + '<br/>' +
					'<b>Fax : </b>' + hall[6] + '</p>' +
					'<p><b>Email : </b>' + hall[7] + '</p></div>';

				google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
					return function () {
						infowindow.setContent(content);
						infowindow.open(map, marker);
					};
				})(marker, content, infowindow));
			}
			zoomChangeBoundsListener =
				google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
					if (this.getZoom()) {
						this.setZoom(16);
					}
				});
			setTimeout(function () {
				google.maps.event.removeListener(zoomChangeBoundsListener)
			}, 2000);
		}

		google.maps.event.addDomListener(window, 'load', initialize);


	// GOOGLE MAP - GLOBAL

		var map2;
		var infowindow2 = new google.maps.InfoWindow();

		var halls2 = [
			['Nviron Bio Solutions', 45.460955, -73.8274627, 4, '1 Holiday Av. East Tower, Suite 501, Pointe-Claire, Quebec, H9R 5N3, Canada', '+1.450.621.5848', '+1.450.621.8767', 'info@nvironbiosolutions.com'],
			['PROXIM S.R.O.', 49.8140546, 15.1999862, 4, '<b>Dr. Jan Kroupa</b><br>Palackeho 578, 530 02 Pardubice, Czech Republic', '+ 420 723752342', '+ 420 466530357', 'info@propoolchem.com'],
			['PROXIM S.R.O.', 44.1047084,20.7933023, 4, '<b>Dr. Jan Kroupa</b><br>Palackeho 578, 530 02 Pardubice, Czech Republic', '+ 420 723752342', '+ 420 466530357', 'info@propoolchem.com'],
			['PROXIM S.R.O.', 55.7107086,23.923654, 4,'<b>Dr. Jan Kroupa</b><br>Palackeho 578, 530 02 Pardubice, Czech Republic', '+ 420 723752342', '+ 420 466530357', 'info@propoolchem.com'],
			['PROXIM S.R.O.', 53.3280637,28.0542092, 4, '<b>Dr. Jan Kroupa</b><br>Palackeho 578, 530 02 Pardubice, Czech Republic', '+ 420 723752342', '+ 420 466530357', 'info@propoolchem.com'],
			['PROXIM S.R.O.', 59.0730377,27.1614955, 4,'<b>Dr. Jan Kroupa</b><br>Palackeho 578, 530 02 Pardubice, Czech Republic', '+ 420 723752342', '+ 420 466530357', 'info@propoolchem.com'],
			['PROXIM S.R.O.', 56.8751505,23.484892, 4, '<b>Dr. Jan Kroupa</b><br>Palackeho 578, 530 02 Pardubice, Czech Republic', '+ 420 723752342', '+ 420 466530357', 'info@propoolchem.com'],
			['KLIO-TRADE', 49.0833489,31.3922326, 4, '49081, Dnepropetrovsk City, 68, Amurskaya Str, Ukraine', '+38 056 374-66-30 (10)', '-', 'valka@ua.fm'],
			
			//Greece
			['ROLCO BIANIL S.A.', 37.9739952, 23.6808772, 4, '111 Agias Annis street, Rentis, 18233 Athens, Greece', '+30 2103493000', '+30 2103426171', 'info@rolco.gr'],
			//['KLIO-TRADE', 48.2621413,67.3459789, 4, '49081, Dnepropetrovsk City, 68, Amurskaya Str, Ukraine', '+38 056 374-66-30 (10)', '-', 'valka@ua.fm'],
			['Global Pillars LLC', 47.0764171,103.0510294, 4, 'ULAANBAATAR', '-', '-', '-'],
			['NVIRON GHANA', 8.1493206,-1.1822464, 4, '<b>Michael Kofi Yeboah</b><br>Gentse Trading & Garage, P.O.Box STC 73, Accra, Ghana-West Africa', '+233-302931932', '+233-208115302', 'gentsetrade@live.co.uk'],
			//['GEORGE DIAMANTIS', 39.6643247,22.6005522, 4, '', '-', '-', 'g.p.diamantis@gmail.com']
			//['PLANET TRADINGS', 23.119504, 52.889901, 4, '<b><br> United Arab Emirates</b>', '-', '-', '-'],
			//['PLANET TRADINGS', 25.9548472,50.3213146, 4, '<b>Bahrein</b>', '-', '-', '-'],
			//['PLANET TRADINGS', 32.3857108,9.7267824, 4, '<b>Oman</b>', '-', '-', '-'],
			//['PLANET TRADINGS', 25.3260455,50.6342254, 4, '<b>Qatar</b>', '-', '-', '-'],
			//['PLANET TRADINGS', 24.0592891,40.6147547, 4, '<b>Saudi Arabia</b>', '-', '-', '-'],
			['AIR PURE', 55.261423, -0.998827, 4, '<b>United Kingdom</b>', '-', '-', '-']//,
		];

		function initialize2() {

			var myLatLng = {lat: 45.460955, lng: -73.8274627};

			// white
			var roadAtlasStyles = [
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
				}, {
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
				}, {
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [{"color": "#ffffff"}, {"lightness": 17}]
				}, {
					"featureType": "road.highway",
					"elementType": "geometry.stroke",
					"stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
				}, {
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [{"color": "#ffffff"}, {"lightness": 18}]
				}, {
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [{"color": "#ffffff"}, {"lightness": 16}]
				}, {
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
				}, {
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [{"color": "#dedede"}, {"lightness": 21}]
				}, {
					"elementType": "labels.text.stroke",
					"stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
				}, {
					"elementType": "labels.text.fill",
					"stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
				}, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
					"featureType": "transit",
					"elementType": "geometry",
					"stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
				}, {
					"featureType": "administrative",
					"elementType": "geometry.fill",
					"stylers": [{"color": "#fefefe"}, {"lightness": 20}]
				}, {
					"featureType": "administrative",
					"elementType": "geometry.stroke",
					"stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
				}
			];
			// green
			var roadAtlasStyles = [{"featureType":"landscape","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":129.33333333333334},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#B6FF00"},{"saturation":63.79310344827584},{"lightness":-47.61960784313726},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#00B7FF"},{"saturation":-31.19999999999996},{"lightness":2.1803921568627374},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#00B5FF"},{"saturation":-33.33333333333343},{"lightness":27.294117647058826},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#00B7FF"},{"saturation":8.400000000000006},{"lightness":36.400000000000006},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#ABFF00"},{"saturation":61.80000000000001},{"lightness":13.800000000000011},{"gamma":1}]}];


			var mapOptions = {
				zoom: 3,
				center: myLatLng,
				scrollwheel: false,
				disableDoubleClickZoom: true,
				mapTypeControlOptions: {
					mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'usroadatlas']
				}
			};

			map2 = new google.maps.Map(document.getElementById('map-canvas-world'),
				mapOptions);

			var styledMapOptions = {};

			var pmgmap = new google.maps.StyledMapType(
				roadAtlasStyles, styledMapOptions);

			map2.mapTypes.set('usroadatlas', pmgmap);
			map2.setMapTypeId('usroadatlas');

			var bounds = new google.maps.LatLngBounds();

			setMarkers2(map2, halls2, bounds);

			map2.fitBounds(bounds);

		}

		function setMarkers2(map2, halls2, bounds) {
			console.log(halls2.length);
			for (var i = 0; i < halls2.length; i++) {
				var hall = halls2[i];
				var marker = new google.maps.Marker({
					position: {lat: hall[1], lng: hall[2]},
					map: map2,
					icon: image,
					title: hall[0],
					zIndex: hall[3]
				});
				bounds.extend(marker.position);


				var content = '<div class="infowindow"><h3>' + hall[0] + '</h3>' +
					'<p>' + hall[4] + '</p>' +
					'<p><b>Tel : </b>' + hall[5] + '<br/>' +
					'<b>Fax : </b>' + hall[6] + '</p>' +
					'<p><b>Email : </b>' + hall[7] + '</p></div>';

				google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow2) {
					return function () {
						infowindow.setContent(content);
						infowindow.open(map2, marker);
					};
				})(marker, content, infowindow2));
			}
			zoomChangeBoundsListener =
				google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
					if (this.getZoom()) {
						this.setZoom(16);
					}
				});
			setTimeout(function () {
				google.maps.event.removeListener(zoomChangeBoundsListener)
			}, 2000);
		}

		google.maps.event.addDomListener(window, 'load', initialize2);
});
